import React, { useState, useEffect } from 'react'
import { useQuery } from 'urql'
import { Button } from 'evergreen-ui'
import PropTypes from 'prop-types'
import urql from 'utils/urql'

import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import ACTIONS from 'constants/actions'
import ROUTES from 'constants/routes'
import { EditorState, convertFromRaw, ContentState } from 'draft-js'

import { isJSONParsable } from 'components/Translations'
import DataGrid from '../components/DataGrid'
import AddEditDialog from './AddEditDialog'
import DeleteSellerDialog from './DeleteSellerDialog'

const getFilenameFromUrl = (url) => url.match('[^/]*$')

let isInitialMount = true

const createMutation = `
mutation($input: CreateSellerInput!) {
  createSeller(input: $input) {
    id
  }
}
`

const updateMutation = `
mutation($input: UpdateSellerInput!) {
  updateSeller(input: $input) {
    id
    gbcPdfUrl
  }
}
`

const SellersList = ({ user, history }) => {
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false)
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [sellersQuery, executeSellersQuery] = useQuery({
    query: `
      query {
        sellers {
          id
          country {
            isoCode
            title
            vat0
            vat1
            vat2
          }
          vatGroup
          accountName
          companyName
          registeredAddress
          zipCode
          companyRegistrationNumber
          dateOfRegistration
          phoneNumber
          fax
          email
          web
          warehouseAddress
          bankName
          iban
          swiftCode
          responsiblePersonFirstName
          responsiblePersonLastName
          responsiblePersonPhoneNumber
          responsiblePersonEmail
          gbcPdfUrl
          isActive
          marketplacePercentageFee
          ordersSyncApiKey
          warehouseDeliveryTimeInDays
          generalBusinessConditionsText
        }
      }
    `,
  })
  const [id, setId] = useState(null)
  const [countryIsoCode, setCountryIsoCode] = useState('')
  const [vatGroup, setVatGroup] = useState('')
  const [accountName, setAccountName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [marketplacePercentageFee, setMarketplacePercentageFee] = useState('')
  const [registeredAddress, setRegisteredAddress] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState('')
  const [dateOfRegistration, setDateOfRegistration] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [fax, setFax] = useState('')
  const [email, setEmail] = useState('')
  const [web, setWeb] = useState('')
  const [warehouseAddress, setWarehouseAddress] = useState('')
  const [bankName, setBankName] = useState('')
  const [iban, setIban] = useState('')
  const [swiftCode, setSwiftCode] = useState('')
  const [ordersSyncApiKey, setOrdersSyncApiKey] = useState('')
  const [responsiblePersonFirstName, setResponsiblePersonFirstName] =
    useState('')
  const [responsiblePersonLastName, setResponsiblePersonLastName] = useState('')
  const [responsiblePersonPhoneNumber, setResponsiblePersonPhoneNumber] =
    useState('')
  const [responsiblePersonEmail, setResponsiblePersonEmail] = useState('')
  const [gbcFile, setGbcFile] = useState({})
  const [isActive, setIsActive] = useState(true)
  const [warehouseDeliveryTimeInDays, setWarehouseDeliveryTimeInDays] =
    useState('')
  const [isSending, setIsSending] = useState(false)
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )

  useEffect(() => {
    if (!isInitialMount) {
      executeSellersQuery({ requestPolicy: 'network-only' })
    } else {
      isInitialMount = false
    }
  }, [executeSellersQuery])
  useEffect(() => {
    const error =
      sellersQuery.error || createMutation.error || updateMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [sellersQuery.error])

  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Sellers</SideNavPage.Title>
        {user.hasAction(ACTIONS.SELLER.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setId(null)
              setCountryIsoCode('')
              setVatGroup('')
              setAccountName('')
              setCompanyName('')
              setMarketplacePercentageFee('')
              setRegisteredAddress('')
              setZipCode('')
              setCompanyRegistrationNumber('')
              setDateOfRegistration('')
              setPhoneNumber('')
              setFax('')
              setEmail('')
              setWeb('')
              setWarehouseAddress('')
              setBankName('')
              setIban('')
              setSwiftCode('')
              setResponsiblePersonFirstName('')
              setResponsiblePersonLastName('')
              setResponsiblePersonPhoneNumber('')
              setResponsiblePersonEmail('')
              setGbcFile('')
              setOrdersSyncApiKey('')
              setIsCreateUserModalOpen(true)
              setIsSending(false)
              setWarehouseDeliveryTimeInDays('')
              setEditorState(() => EditorState.createEmpty())
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <DataGrid
          onRowClick={(seller) =>
            history.push(`/${ROUTES.SELLERS}/${seller.id}`)
          }
          handleReturnAddressClick={(seller) => {
            history.push(`/${ROUTES.SELLERS}/return-address/${seller.id}`)
          }}
          isSelectable
          isFetching={sellersQuery.fetching}
          sellers={sellersQuery.data ? sellersQuery.data.sellers : []}
          handleEditSellerClick={(seller) => {
            setId(seller.id)
            setCountryIsoCode(seller.country.isoCode)
            setVatGroup(seller.vatGroup)
            setAccountName(seller.accountName || '')
            setCompanyName(seller.companyName || '')
            setMarketplacePercentageFee(seller.marketplacePercentageFee || '')
            setRegisteredAddress(seller.registeredAddress || '')
            setZipCode(seller.zipCode || '')
            setCompanyRegistrationNumber(seller.companyRegistrationNumber || '')
            setDateOfRegistration(seller.dateOfRegistration || '')
            setPhoneNumber(seller.phoneNumber || '')
            setFax(seller.fax || '')
            setEmail(seller.email || '')
            setWeb(seller.web || '')
            setWarehouseAddress(seller.warehouseAddress || '')
            setBankName(seller.bankName || '')
            setIban(seller.iban || '')
            setSwiftCode(seller.swiftCode || '')
            setResponsiblePersonFirstName(
              seller.responsiblePersonFirstName || ''
            )
            setResponsiblePersonLastName(seller.responsiblePersonLastName || '')
            setResponsiblePersonPhoneNumber(
              seller.responsiblePersonPhoneNumber || ''
            )
            setResponsiblePersonEmail(seller.responsiblePersonEmail || '')
            setGbcFile({ name: getFilenameFromUrl(seller.gbcPdfUrl) } || {})
            setOrdersSyncApiKey(seller.ordersSyncApiKey)
            setIsActive(seller.isActive)
            setWarehouseDeliveryTimeInDays(seller.warehouseDeliveryTimeInDays)
            setIsUpdateUserModalOpen(true)
            if (seller.generalBusinessConditionsText) {
              setEditorState(() =>
                EditorState.createWithContent(
                  isJSONParsable(seller.generalBusinessConditionsText)
                    ? convertFromRaw(
                        JSON.parse(seller.generalBusinessConditionsText)
                      )
                    : ContentState.createFromText(
                        seller.generalBusinessConditionsText
                      )
                )
              )
            } else {
              setEditorState(() => EditorState.createEmpty())
            }
          }}
          handleDeleteSellerClick={(seller) => {
            setId(seller.id)
            setCompanyName(seller.companyName)
            setIsConfirmDeleteModalOpen(true)
          }}
          isWithEditButton={user.hasAction(ACTIONS.SELLER.UPDATE)}
          isWithDeleteButton={user.hasAction(ACTIONS.SELLER.DELETE)}
        />
      </SideNavPage.Content>
      <AddEditDialog
        id={id}
        countryIsoCode={countryIsoCode}
        vatGroup={vatGroup}
        accountName={accountName}
        companyName={companyName}
        marketplacePercentageFee={marketplacePercentageFee}
        registeredAddress={registeredAddress}
        zipCode={zipCode}
        companyRegistrationNumber={companyRegistrationNumber}
        dateOfRegistration={dateOfRegistration}
        phoneNumber={phoneNumber}
        fax={fax}
        email={email}
        web={web}
        warehouseAddress={warehouseAddress}
        bankName={bankName}
        iban={iban}
        swiftCode={swiftCode}
        ordersSyncApiKey={ordersSyncApiKey}
        responsiblePersonFirstName={responsiblePersonFirstName}
        responsiblePersonLastName={responsiblePersonLastName}
        responsiblePersonPhoneNumber={responsiblePersonPhoneNumber}
        responsiblePersonEmail={responsiblePersonEmail}
        gbcFile={gbcFile}
        isActive={isActive}
        warehouseDeliveryTimeInDays={warehouseDeliveryTimeInDays}
        isSending={isSending}
        isCreateUserModalOpen={isCreateUserModalOpen}
        isUpdateUserModalOpen={isUpdateUserModalOpen}
        setIsCreateUserModalOpen={setIsCreateUserModalOpen}
        setIsUpdateUserModalOpen={setIsUpdateUserModalOpen}
        setIsSending={setIsSending}
        user={user}
        executeSellersQuery={executeSellersQuery}
        setId
        setCountryIsoCode={setCountryIsoCode}
        setVatGroup={setVatGroup}
        setAccountName={setAccountName}
        setCompanyName={setCompanyName}
        setMarketplacePercentageFee={setMarketplacePercentageFee}
        setRegisteredAddress={setRegisteredAddress}
        setZipCode={setZipCode}
        setCompanyRegistrationNumber={setCompanyRegistrationNumber}
        setDateOfRegistration={setDateOfRegistration}
        setPhoneNumber={setPhoneNumber}
        setFax={setFax}
        setEmail={setEmail}
        setWeb={setWeb}
        setWarehouseAddress={setWarehouseAddress}
        setBankName={setBankName}
        setIban={setIban}
        setSwiftCode={setSwiftCode}
        setResponsiblePersonFirstName={setResponsiblePersonFirstName}
        setResponsiblePersonLastName={setResponsiblePersonLastName}
        setResponsiblePersonPhoneNumber={setResponsiblePersonPhoneNumber}
        setResponsiblePersonEmail={setResponsiblePersonEmail}
        setOrdersSyncApiKey={setOrdersSyncApiKey}
        setIsActive={setIsActive}
        setWarehouseDeliveryTimeInDays={setWarehouseDeliveryTimeInDays}
        editorState={editorState}
        setEditorState={setEditorState}
      />
      <DeleteSellerDialog
        id={id}
        companyName={companyName}
        setIsConfirmDeleteModalOpen={setIsConfirmDeleteModalOpen}
        isConfirmDeleteModalOpen={isConfirmDeleteModalOpen}
        setId={setId}
        setCompanyName={setCompanyName}
        executeSellersQuery={executeSellersQuery}
      />
    </React.Fragment>
  )
}

SellersList.propTypes = {
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(SellersList)

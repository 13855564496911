import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import {
  Button,
  TextInputField,
  Label,
  Pane,
  SelectField,
  majorScale,
  toaster,
  Switch,
  Alert,
  Badge,
} from 'evergreen-ui'
import { withRouter } from 'react-router-dom'
import { ContentState, EditorState, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import { SIZES_OPTIONS } from 'constants/product'
import AdditionalImages from 'components/AdditionalImages'
import Translations, { isJSONParsable } from 'components/Translations'
import MainImage from 'components/MainImage'
import EditorDeleteCustomOption from 'components/EditorDeleteCustomOption'
import { convertEditorStateToRawJson } from 'utils/convertToRaw'
import ProductAttributeValues from './ProductAttributeValues'
import VariantList from './VariantList'
import { getPathToProduct } from '../utils'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export const formatDataIntoInput = ({
  isCopy = false,
  isDraft = false,
  title,
  shortDescription,
  longDescription,
  pictureSpatial,
  productConditionId,
  reviewScore,
  size,
  translations,
  mainImage,
  additionalImages,
  isPhoneServiceEnabled,
  productAttributeValues,
  categoryId,
  productVariants,
}) => {
  const toSave = {
    isDraft,
    title: title.trim(),
    shortDescription,
    longDescription,
    pictureSpatial,
    productConditionId: productConditionId && parseInteger(productConditionId),
    reviewScore: reviewScore && parseFloat(reviewScore),
    size,
    translations:
      translations &&
      translations.map(({ __typename, language, ...rest }) => ({
        ...rest,
        languageIsoCode: language.isoCode,
      })),
    mainImageId: mainImage && parseInteger(mainImage.id),
    additionalImagesIds: additionalImages
      ? additionalImages.map(({ id: imageId }) => parseInteger(imageId))
      : [],
    isCatalogProduct: true,
    isPhoneServiceEnabled,
    productAttributeValues:
      productAttributeValues &&
      productAttributeValues.map(
        ({ __typename, attribute, attributeValue, ...rest }) => ({
          ...rest,
          attributeId: attribute && attribute.id,
          attributeValueId: attributeValue ? attributeValue.id : undefined,
        })
      ),
    categoryId: categoryId && parseInteger(categoryId),
    ...(productVariants && productVariants.length
      ? {
          productVariants: productVariants.map(
            ({
              id,
              mainImage: variantMainImage,
              ean,
              productVariantAttributeValues,
              additionalImages: variantAdditionalImages,
            }) => ({
              ...(isCopy ? {} : { id }),
              ...(ean ? { ean } : {}),
              ...(variantMainImage
                ? {
                    mainImageId:
                      variantMainImage && parseInteger(variantMainImage.id),
                  }
                : {}),
              additionalImagesIds: variantAdditionalImages
                ? variantAdditionalImages.map(({ id: imageId }) =>
                    parseInteger(imageId)
                  )
                : [],
              productVariantAttributeValues:
                productVariantAttributeValues &&
                productVariantAttributeValues.map(
                  ({ __typename, attributeValue, ...rest }) => ({
                    ...rest,
                    attributeId: attributeValue && attributeValue.attributeId,
                    attributeValueId: attributeValue && attributeValue.id,
                  })
                ),
            })
          ),
        }
      : {}),
  }
  return Object.keys(toSave).reduce((acc, key) => {
    if (toSave[key] === undefined || (isCopy && toSave[key] === null)) {
      return isDraft ? acc : { ...acc, [key]: null }
    }
    return { ...acc, [key]: toSave[key] }
  }, {})
}

const navigateToNewProductOnSuccess = ({ result, history }) => {
  if (!result.error) {
    history.replace(
      getPathToProduct({
        productId: result.data.createProduct.id,
      })
    )
  }
}

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const ProductForm = (props) => {
  const {
    data = {},
    isReadOnly,
    history,
    isTranslationEditReadOnly,
    isTranslationAddReadOnly,
  } = props
  const [shortDescription, setShortDescription] = React.useState(() =>
    EditorState.createEmpty()
  )
  const [longDescription, setLongDescription] = React.useState(() =>
    EditorState.createEmpty()
  )
  const [pictureSpatial, setPictureSpatial] = useState(
    data.pictureSpatial || null
  )
  const [title, setTitle] = useState(data.title || undefined)
  const [reviewScore, setReviewScore] = useState(data.reviewScore || undefined)
  const [size, setSize] = useState(data.size || undefined)
  const [productConditionId, setProductConditionId] = useState(
    data.productCondition ? data.productCondition.id : undefined
  )
  const [translations, setTranslations] = useState(data.translations || [])
  const [mainImage, setMainImage] = useState(data.mainImage || undefined)
  const [additionalImages, setAdditionalImages] = useState(
    data.additionalImages || []
  )
  const [categoryId, setCategoryId] = useState(
    (data.category && data.category.id) || undefined
  )
  const [isPhoneServiceEnabled, setIsPhoneServiceEnabled] = useState(
    data.id ? data.isPhoneServiceEnabled || false : true
  )
  const [productAttributeValues, setProductAttributeValues] = useState(
    data.productAttributeValues || []
  )
  const [productVariants, setProductVariants] = useState(
    data.productVariants || []
  )

  useEffect(() => {
    if (data.shortDescriptionHtml) {
      setShortDescription(() =>
        EditorState.createWithContent(
          isJSONParsable(data.shortDescriptionHtml)
            ? convertFromRaw(JSON.parse(data.shortDescriptionHtml))
            : ContentState.createFromText(data.shortDescriptionHtml)
        )
      )
    }
  }, [data.shortDescriptionHtml])

  useEffect(() => {
    if (data.longDescriptionHtml) {
      setLongDescription(() =>
        EditorState.createWithContent(
          isJSONParsable(data.longDescriptionHtml)
            ? convertFromRaw(JSON.parse(data.longDescriptionHtml))
            : ContentState.createFromText(data.longDescriptionHtml)
        )
      )
    }
  }, [data.longDescriptionHtml])

  const [productConditionsQuery] = useQuery({
    query: `
      query {
        productConditions {
          id
          title
        }
      }
    `,
  })
  const [categoriesQuery] = useQuery({
    query: `
      query {
        categories {
          id
          title
          parentId
        }
      }
    `,
  })
  const transformCategoriesToTree = (categories, id = null) =>
    categories
      .filter((item) => item.parentId === id)
      .map((item) => {
        const selectedCategory = item.id === categoryId
        return {
          checked: selectedCategory,
          expanded: selectedCategory,
          value: item.id,
          label: item.title,
          children: transformCategoriesToTree(categories, item.id),
        }
      })
  const categories = categoriesQuery.data && categoriesQuery.data.categories
  const categoryTree = transformCategoriesToTree(categories || [])
  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateProductInput!) {
      createProduct(input: $input) {
        id
      }
    }
  `)
  const [, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateProductInput!) {
      updateProduct(input: $input) {
        id
      }
    }
  `)
  const dataToSave = {
    title,
    shortDescription: convertEditorStateToRawJson(shortDescription),
    longDescription: convertEditorStateToRawJson(longDescription),
    productConditionId,
    reviewScore,
    pictureSpatial,
    size,
    translations,
    mainImage,
    additionalImages,
    isPhoneServiceEnabled,
    productAttributeValues,
    categoryId,
    productVariants,
  }
  return (
    <Pane>
      <Pane maxWidth={900}>
        {data.id && (
          <>
            {!productVariants.length && (
              <Alert
                intent="warning"
                title="This product has no variant. It will not be displayed."
                marginBottom={32}
              />
            )}
            {data.isDraft && (
              <Badge color="purple" isSolid marginBottom={majorScale(1)}>
                Draft
              </Badge>
            )}
          </>
        )}
        <TextInputField
          label="Title"
          disabled={isReadOnly}
          value={title || ''}
          onChange={({ target }) => {
            setTitle(target.value === '' ? undefined : target.value)
          }}
        />
        <MainImage
          mainImage={mainImage}
          setMainImage={setMainImage}
          isDisabled={isReadOnly}
        />
        <Pane>
          <Label
            htmlFor="textarea-short-description"
            marginBottom={majorScale(1)}
            display="block"
          >
            Short Description
          </Label>
          <Editor
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
            }}
            editorState={shortDescription}
            editorStyle={{
              border: '1px solid #e2e2e2',
              height: 300,
            }}
            onEditorStateChange={setShortDescription}
            toolbarCustomButtons={[<EditorDeleteCustomOption />]}
          />
        </Pane>
        <Pane>
          <Label
            htmlFor="textarea-long-description"
            marginBottom={majorScale(1)}
            display="block"
          >
            Long Description
          </Label>
          <Editor
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
            }}
            editorState={longDescription}
            editorStyle={{
              border: '1px solid #e2e2e2',
              height: 300,
            }}
            onEditorStateChange={setLongDescription}
            toolbarCustomButtons={[<EditorDeleteCustomOption />]}
          />
        </Pane>
        <TextInputField
          label="Review Score"
          type="number"
          disabled={isReadOnly}
          step={0.5}
          min={0}
          max={5}
          value={reviewScore || ''}
          onChange={({ target }) => {
            setReviewScore(target.value === '' ? undefined : target.value)
          }}
        />
        <SelectField
          label="Size"
          disabled={isReadOnly}
          value={size || ''}
          onChange={({ target }) => {
            setSize(target.value === '' ? undefined : target.value)
          }}
        >
          <option value="">---Select size---</option>
          {SIZES_OPTIONS.map((sizeOption) => (
            <option key={sizeOption} value={sizeOption}>
              {sizeOption}
            </option>
          ))}
        </SelectField>
        <SelectField
          label="Product Condition"
          value={productConditionId || ''}
          disabled={isReadOnly}
          onChange={({ target }) => {
            setProductConditionId(
              target.value === '' ? undefined : target.value
            )
          }}
        >
          <option value="">---Select Product Condition---</option>
          {productConditionsQuery.data &&
            productConditionsQuery.data.productConditions.map((condition) => (
              <option key={condition.id} value={condition.id}>
                {condition.title}
              </option>
            ))}
        </SelectField>
        <TextInputField
          label="Model 3D"
          type="string"
          disabled={isReadOnly}
          value={pictureSpatial || ''}
          onChange={({ target }) => {
            setPictureSpatial(target.value === '' ? undefined : target.value)
          }}
        />
        <AdditionalImages
          isDisabled={isReadOnly}
          additionalImages={additionalImages}
          setAdditionalImages={setAdditionalImages}
        />
        <Translations
          isAddDisabled={isTranslationAddReadOnly}
          isEditDisabled={isTranslationEditReadOnly}
          translations={translations}
          handleSaveTranslations={setTranslations}
        />
        <Pane marginTop={majorScale(1)}>
          <Label>Category</Label>
          <Pane marginTop={majorScale(1)}>
            <DropdownTreeSelect
              data={categoryTree}
              onChange={({ value }) => setCategoryId(value)}
              mode="radioSelect"
              texts={{
                placeholder: 'Select category',
              }}
              keepTreeOnSearch
            />
          </Pane>
        </Pane>
        <Pane marginTop={majorScale(2)}>
          <Label>Enabled for phone service</Label>
          <Switch
            marginTop={majorScale(1)}
            height={20}
            checked={isPhoneServiceEnabled}
            disabled={isReadOnly}
            onChange={(e) => {
              setIsPhoneServiceEnabled(e.target.checked)
            }}
          />
        </Pane>
        <ProductAttributeValues
          productAttributeValues={productAttributeValues}
          productVariants={productVariants}
          handleSave={({ attributeValues, variants }) => {
            setProductAttributeValues(attributeValues)
            setProductVariants(variants)
          }}
          isAddDisabled={isReadOnly}
          isEditDisabled={isReadOnly}
          productId={data.id}
        />
        {data.id && (
          <VariantList
            productVariants={productVariants}
            productAttributeValues={productAttributeValues}
            productId={data.id}
            onVariantDeleteSuccess={(updatedProductVariants) =>
              setProductVariants(updatedProductVariants)
            }
          />
        )}
        <Pane marginTop={majorScale(2)} justifyContent="center" display="flex">
          <Button
            intent="success"
            marginRight={majorScale(2)}
            onClick={async () => {
              if (data.id) {
                const { error } = await executeUpdateMutation({
                  input: {
                    id: parseInteger(data.id),
                    ...formatDataIntoInput({
                      ...dataToSave,
                      isDraft: true,
                    }),
                  },
                })
                displayToastOnSave(error)
              } else {
                const result = await executeCreateMutation({
                  input: formatDataIntoInput({
                    ...dataToSave,
                    isDraft: true,
                  }),
                })
                displayToastOnSave(result.error)
                navigateToNewProductOnSuccess({
                  result,
                  history,
                })
              }
            }}
          >
            Save as draft
          </Button>
          <Button
            appearance="primary"
            intent="success"
            onClick={async () => {
              if (data.id) {
                const { error } = await executeUpdateMutation({
                  input: {
                    id: parseInteger(data.id),
                    ...formatDataIntoInput(dataToSave),
                  },
                })
                displayToastOnSave(error)
              } else {
                const result = await executeCreateMutation({
                  input: formatDataIntoInput(dataToSave),
                })
                displayToastOnSave(result.error)
                navigateToNewProductOnSuccess({
                  result,
                  history,
                })
              }
            }}
          >
            Save
          </Button>
        </Pane>
      </Pane>
    </Pane>
  )
}

ProductForm.defaultProps = {
  isReadOnly: false,
  isTranslationEditReadOnly: false,
  isTranslationAddReadOnly: false,
}

ProductForm.propTypes = {
  data: PropTypes.object,
  isReadOnly: PropTypes.bool,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
  isTranslationEditReadOnly: PropTypes.bool,
  isTranslationAddReadOnly: PropTypes.bool,
}

export default withRouter(ProductForm)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import {
  Table,
  Button,
  Dialog,
  TextInputField,
  Label,
  Pane,
  SelectField,
  majorScale,
} from 'evergreen-ui'
import find from 'lodash.find'
import { ContentState, convertFromRaw, EditorState } from 'draft-js'
import { convertEditorStateToRawJson } from 'utils/convertToRaw'
import { Editor } from 'react-draft-wysiwyg'
import EditorDeleteCustomOption from './EditorDeleteCustomOption'

export function isJSONParsable(string) {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

const Translations = ({
  translations,
  handleSaveTranslations,
  isEditDisabled,
  isAddDisabled,
}) => {
  const [isAddTranslationDialogOpen, setIsAddTranslationDialogOpen] =
    useState(false)
  const [isEditTranslationDialogOpen, setIsEditTranslationDialogOpen] =
    useState(false)
  const [language, setLanguage] = useState({})
  const [titleTranslation, setTitleTranslation] = useState('')
  const [shortDescriptionTranslation, setShortDescriptionTranslation] =
    useState(() => EditorState.createEmpty())
  const [longDescriptionTranslation, setLongDescriptionTranslation] = useState(
    () => EditorState.createEmpty()
  )
  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })

  return (
    <Pane>
      <Label marginBottom={majorScale(1)} display="block">
        Translations
      </Label>
      {!isAddDisabled && (
        <Button
          marginBottom={majorScale(1)}
          onClick={() => {
            setLanguage({})
            setTitleTranslation('')
            setShortDescriptionTranslation(() => EditorState.createEmpty())
            setLongDescriptionTranslation(() => EditorState.createEmpty())
            setIsAddTranslationDialogOpen(true)
          }}
        >
          Add Translation
        </Button>
      )}
      <Table marginTop={majorScale(1)}>
        <Table.Head>
          <Table.TextHeaderCell>Language</Table.TextHeaderCell>
          <Table.TextHeaderCell>Title</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {translations &&
            translations.map((translation) => (
              <Table.Row key={translation.language.isoCode}>
                <Table.TextCell>{translation.language.title}</Table.TextCell>
                <Table.TextCell>{translation.title}</Table.TextCell>
                <Table.Cell>
                  <Button
                    disabled={isEditDisabled}
                    marginRight={majorScale(2)}
                    iconBefore="edit"
                    onClick={(e) => {
                      e.stopPropagation()
                      setLanguage(translation.language)
                      setTitleTranslation(translation.title)
                      setShortDescriptionTranslation(() =>
                        EditorState.createWithContent(
                          isJSONParsable(translation.shortDescription)
                            ? convertFromRaw(
                                JSON.parse(translation.shortDescription)
                              )
                            : ContentState.createFromText(
                                translation.shortDescription
                              )
                        )
                      )
                      setLongDescriptionTranslation(() =>
                        EditorState.createWithContent(
                          isJSONParsable(translation.longDescription)
                            ? convertFromRaw(
                                JSON.parse(translation.longDescription)
                              )
                            : ContentState.createFromText(
                                translation.longDescription
                              )
                        )
                      )
                      setIsEditTranslationDialogOpen(true)
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isEditDisabled}
                    marginRight={majorScale(2)}
                    iconBefore="trash"
                    intent="danger"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleSaveTranslations(
                        translations.filter(
                          (addedTranslation) =>
                            addedTranslation.language.isoCode !==
                            translation.language.isoCode
                        )
                      )
                    }}
                  >
                    Delete
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Dialog
        isShown={isAddTranslationDialogOpen || isEditTranslationDialogOpen}
        title={
          isAddTranslationDialogOpen ? 'Add Translation' : `Edit Translation`
        }
        hasCancel={false}
        confirmLabel={isAddTranslationDialogOpen ? 'Add' : 'Edit'}
        onCloseComplete={() => {
          setIsAddTranslationDialogOpen(false)
          setIsEditTranslationDialogOpen(false)
        }}
        onConfirm={(close) => {
          if (isAddTranslationDialogOpen && language.isoCode) {
            handleSaveTranslations([
              ...translations,
              {
                language,
                title: titleTranslation,
                shortDescription: convertEditorStateToRawJson(
                  shortDescriptionTranslation
                ),
                longDescription: convertEditorStateToRawJson(
                  longDescriptionTranslation
                ),
              },
            ])
            close()
          }
          if (isEditTranslationDialogOpen) {
            handleSaveTranslations(
              translations.map((addedTranslation) => {
                if (addedTranslation.language.isoCode === language.isoCode) {
                  return {
                    language,
                    title: titleTranslation,
                    shortDescription: convertEditorStateToRawJson(
                      shortDescriptionTranslation
                    ),
                    longDescription: convertEditorStateToRawJson(
                      longDescriptionTranslation
                    ),
                  }
                }
                return addedTranslation
              })
            )
            close()
          }
        }}
      >
        <SelectField
          label="Language"
          value={language.isoCode}
          disabled={isEditTranslationDialogOpen}
          onChange={(e) => {
            const useLanguage = find(languagesQuery.data.languages, {
              isoCode: e.target.value,
            })
            setLanguage(useLanguage)
          }}
        >
          <option value="">---Select language---</option>
          {languagesQuery.data &&
            languagesQuery.data.languages.map((useLanguage) => (
              <option key={useLanguage.isoCode} value={useLanguage.isoCode}>
                {useLanguage.title}
              </option>
            ))}
        </SelectField>
        <TextInputField
          label="Title"
          required
          value={titleTranslation}
          onChange={(e) => {
            setTitleTranslation(e.target.value)
          }}
        />
        <Pane>
          <Label
            htmlFor="textarea-short-description-translation"
            marginBottom={majorScale(1)}
            display="block"
          >
            Short Description
          </Label>
          <Editor
            id="textarea-short-description-translation"
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
            }}
            editorState={shortDescriptionTranslation}
            editorStyle={{
              border: '1px solid #e2e2e2',
              height: 300,
            }}
            onEditorStateChange={setShortDescriptionTranslation}
            toolbarCustomButtons={[<EditorDeleteCustomOption />]}
          />
        </Pane>
        <Pane>
          <Label
            htmlFor="textarea-long-description-translation"
            marginBottom={majorScale(1)}
            display="block"
          >
            Long Description
          </Label>
          <Editor
            id="textarea-long-description-translation"
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
            }}
            editorState={longDescriptionTranslation}
            editorStyle={{
              border: '1px solid #e2e2e2',
              height: 300,
            }}
            onEditorStateChange={setLongDescriptionTranslation}
            toolbarCustomButtons={[<EditorDeleteCustomOption />]}
          />
        </Pane>
      </Dialog>
    </Pane>
  )
}

Translations.propTypes = {
  handleSaveTranslations: PropTypes.func.isRequired,
  isEditDisabled: PropTypes.bool.isRequired,
  isAddDisabled: PropTypes.bool.isRequired,
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.shape({
        title: PropTypes.string,
        isoCode: PropTypes.string,
      }),
      title: PropTypes.string,
      shortDescription: PropTypes.string,
      longDescription: PropTypes.string,
      languageIsoCode: PropTypes.string,
    })
  ),
}

export default Translations
